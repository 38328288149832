import { Lines } from "react-preloading-component";
import "./index.css";

const LoadingComponent = () => {
  return (
    <div className="LoadingContent globalMargin">
      <h2 className="LoadingHeader header">Lade Seite...</h2>
      <div className="LoadingBody">
        <Lines color="#93b876" />
      </div>
    </div>
  );
};

export default LoadingComponent;
