import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import Text from "./text";
import Pages from "../Pages";

const Navigation = ({}) => {
  const location = useLocation();

  const RenderNavigationElements = () => {
    return Object.keys(Pages).map((key) => {
      const isCurrent = location.pathname === Pages[key].path;

      return (
        <div
          key={key}
          className={`Tab TabWrapper ${isCurrent ? "TabLeft" : "TabUnCurrent"}`}
        >
          <Link
            className={`Tab ${isCurrent ? "TabRight" : "TextUnCurrent"}`}
            to={Pages[key].path}
          >
            {key}
          </Link>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <div className="Navigation Background">
        <RenderNavigationElements />
      </div>

      <Text />
    </Fragment>
  );
};

export default Navigation;
