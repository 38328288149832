import { Link } from "react-router-dom";
import "./top.css";
import Pages, { hiddenPages } from "../Pages";
import alertify from "alertifyjs/build/alertify.min.js";
import "alertifyjs/build/css/alertify.min.css";
import satzung from "../pdfs/satzung.pdf";
import beitrittserklaerung from "../pdfs/Aufnahme Antrag FBG neu.pdf";

const TopSidebar = ({}) => {
  const alert_function_pflanzen = () => {
    alertify.alert(
      "",
      "Für die Forstpflanzenbestellung " +
        new Date().getFullYear() +
        " gelten folgende Termine verbindlich.\n" +
        "Pflanzenbestellungen für die Frühjahrspflanzung bis zum Ende Februar. Pflanzenbestellungen für die Herbstpflanzungen bis zum Ende August." +
        " Bestellungen bitte bei Herr Marvin Stiehl oder der Geschäftsführerin der FBGBreun, Frau Beatrix Schmitz abgeben.",
    );
  };

  const alert_function_wild = () => {
    alertify.alert(
      "",
      "Anmeldungen von Wildschaden im Wald, nach § 34 Bundesjagdgesetz. Für den entstandenen Schaden im Winter," +
        " bis zum 01.Mai und für Schaden im Sommer, bis zum 01.Oktober jeden Jahres bei der Gemeindeverwaltung Lindlar zu melden." +
        " Meldestelle ist das Ordnungsamt der Gemeinde Lindlar.",
    );
  };

  return (
    <ul className="SidebarList">
      <li className="ListElement">
        <Link to={Pages.WALDBETREUUNG.path}>Förster</Link>
      </li>
      <li className="ListElement">
        <Link to={Pages.KONTAKT.path}>Kontakt</Link>
      </li>
      <li className="ListElement">
        <a rel="noopener noreferrer" target="_blank" href={beitrittserklaerung}>
          Beitrittserklärung
        </a>
      </li>
      <li className="ListElement">
        <a rel="noopener noreferrer" target="_blank" href={satzung}>
          Unsere Satzung
        </a>
      </li>
      <li className="ListElement LinkFix">
        <a onClick={alert_function_pflanzen}>Forstpflanzenbestellung</a>
      </li>
      <li className="ListElement LinkFix">
        <a onClick={alert_function_wild}>Wildschadensmeldung</a>
      </li>
      <li className="ListElement">
        <Link to={hiddenPages.BETRIEBSHAFTPFLICHT.path}>
          Betriebshaftpflicht
        </Link>
      </li>
      <li className="ListElement">
        <Link to={hiddenPages.WALDBRANDVERSICHERUNG.path}>
          Waldbrandversicherung
        </Link>
      </li>
    </ul>
  );
};

export default TopSidebar;
