import { Fragment, Suspense } from "react";
import "./index.css";
import Sidebar, { BottomSidebar, TopSidebar } from "../Sidebar";
import Footer from "../Footer";
import Navigation from "../Navigation";
import LoadingComponent from "../Loading/index";

const Content = ({ children: Children }) => {
  return (
    <Fragment>
      <Navigation />

      <span className="Content">
        <Suspense fallback={<LoadingComponent />}>
          <Children />
        </Suspense>

        <div>
          <Sidebar Title={"Sie suchen..."}>
            <TopSidebar />
          </Sidebar>

          <Sidebar Title={"Links"}>
            <BottomSidebar />
          </Sidebar>
        </div>
      </span>

      <Footer />
    </Fragment>
  );
};

export default Content;
