import "core-js/stable";
import "regenerator-runtime/runtime";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import "./Styling/global.css";
import "./imagesupport";
import Pages, { hiddenPages } from "./Pages/index";

const container = document.getElementById("root");
const root = createRoot(container);

const router = createHashRouter([
  ...Object.values(Pages),
  ...Object.values(hiddenPages),
]);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
