import "./index.css";
export { default as TopSidebar } from "./top";
export { default as BottomSidebar } from "./bottom";

const Sidebar = ({ Title, children }) => {
  return (
    <div className="SidebarWrapper globalMargin">
      <h3 className="SidebarHeader">{Title}</h3>
      <div className="SidebarBody">{children}</div>
    </div>
  );
};

export default Sidebar;
