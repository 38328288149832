import { lazy } from "react";
import Content from "../Content";
import Home from "./home";

const Aktuelles = lazy(() => import("./aktuelles"));
const Waldbetreuung = lazy(() => import("./waldbetreuung"));
const Kontakt = lazy(() => import("./kontakt"));
const Karte = lazy(() => import("./karte"));
const Waldbrandversicherung = lazy(() => import("./waldbrandversicherung"));
const Betriebshaftpflicht = lazy(() => import("./betriebshaftpflicht"));

export default {
  START: {
    path: "/",
    element: <Content children={Home} />,
  },
  AKTUELLES: { path: "/Aktuelles", element: <Content children={Aktuelles} /> },
  WALDBETREUUNG: {
    path: "/Waldbetreuung",
    element: <Content children={Waldbetreuung} />,
  },
  KONTAKT: { path: "/Kontakt", element: <Content children={Kontakt} /> },
  "HISTORISCHE KARTE": {
    path: "/Karte",
    element: <Content children={Karte} />,
  },
};

export const hiddenPages = {
  WALDBRANDVERSICHERUNG: {
    path: "/Waldbrandversicherung",
    element: <Content children={Waldbrandversicherung} />,
  },
  BETRIEBSHAFTPFLICHT: {
    path: "/Betriebshaftpflicht",
    element: <Content children={Betriebshaftpflicht} />,
  },
};
