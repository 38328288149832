import "./text.css";

const Text = () => {
  return (
    <div className="Text">
      <div className="Title">FBG Breun</div>
      <div className="SubTitle">Die Forstbetriebsgemeinschaft für Breun</div>
    </div>
  );
};

export default Text;
