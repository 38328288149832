import { Link } from "react-router-dom";
import "./index.css";
import Pages from "../Pages";

const Navigation = ({}) => {
  return (
    <div className="FooterWrapper">
      <div className="Copyright">
        © {new Date().getFullYear()} Copyright{" "}
        <Link to={Pages.KONTAKT.path}>
          Forstbetriebsgemeinschaft&nbsp;Breun
        </Link>{" "}
        | Design:&nbsp;
        <a href="http://www.styleshout.com/">styleshout</a>
      </div>
      <div className="Links">
        <Link to={Pages.START.path}>Start</Link> |{" "}
        <Link to={Pages.KONTAKT.path}>Impressum</Link>
      </div>
    </div>
  );
};

export default Navigation;
