import "./bottom.css";

const BottomSidebar = ({}) => {
  const links = [
    {
      href: "http://www.waldbauernverband.de/",
      text: "Waldbauernverband",
    },
    {
      href: "https://www.wald-und-holz.nrw.de/ueber-uns/einrichtungen/regionalforstaemter/bergisches-land/",
      text: "Regionalforstamt",
    },
    {
      href: "http://www.Lindlar.de/",
      text: "Gemeinde Lindlar",
    },
    {
      href: "http://www.pefc.de/",
      text: "PEFC Deutschland e.V.",
    },
    {
      href: "https://www.wald-und-holz.nrw.de/ueber-uns/mensch-wald",
      text: "MENSCH WALD! Filmwettbewerb des Landes NRW",
    },
    {
      href: "http://www.rio.obk.de/",
      text: "Rauminformation Oberberg - Info Seite",
    },
  ];

  return (
    <ul className="SidebarList">
      {links.map(({ href, text }, index) => {
        return (
          <li className="ListElement" key={index}>
            <a href={href} rel="noopener noreferrer" target="_blank">
              {text}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default BottomSidebar;
